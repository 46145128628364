/**
 * This value used as key for purchased_courses/roles data in account and it the same for all our environments,
 * details to debug in $profileInfo
 */
export const PRODUCTION_URL = 'https://ecoreps.de';
export const DOMAIN = PRODUCTION_URL;

export const PROFILE_INFO_URL = `${DOMAIN}/purchased_courses`;
export const ROLES_URL = `${DOMAIN}/roles`;
export const COURSE_ACCESS = `${DOMAIN}/courseAccess`;
export const TOKEN_VERSION = `${DOMAIN}/version`;
export const HOME_PAGE_URL_FRAGMENT = 'home';
export enum STORAGE_KEYS {
  EXPIRE = 'expires_at',
  ID_TOKEN = 'id_token',
  ACCESS_TOKEN = 'access_token',
  IS_LOGGED_IN = 'is_loggedIn',
  ANONYMOUS = 'anonymous',
  SAFARI_LOGGED_OUT='safariLoggedOut'
}

export const ONE_DAY_IN_SECONDS = 86400;

export const WHITE_LISTED_DOMAINS = [
  'https://api.ecoreps.de',
  'api.ecoreps.de',
  'ecoreps.de',
  'https://ecoreps.de',
  'e-learning.ecoreps.de',
  'api.ecoreps.huck-it.de',
  'api.e-learning.ecoreps.de',
  'localhost:5000',
  'localhost:5001',
  'localhost:5002',
  'localhost:5003',
  'localhost:5005',  // ingestion
  'localhost:5007',  // public notification service
  'localhost:5009', // content service
  'localhost:5010', // media service
  'localhost:11001', // chatbot service
  'ecoreps-staging.ninf.de',
  'https://ecoreps-staging.ninf.de',
  'https://huck-it.de',
  'https://devapi.ecoreps.huck-it.de',
  'devapi.ecoreps.huck-it.de',
  'https://devapi2.ecoreps.huck-it.de'
];

