import { env, FullEnvironment } from "./environment.common";

const baseUrl = "https://ecoreps.huck-it.de/";
const API_URL = "https://api.ecoreps.huck-it.de/v2";
const API_INGESTION = "";
export const environment: FullEnvironment = {
  ...env,
  baseUrl,
  production: false,
  API_URL,
  API_URL_PROFILE: API_URL,
  API_NOTIFICATION: API_URL,
  API_CONTENT: API_URL,
  API_CHATBOT: API_URL,
  API_MEDIA: API_URL,
  API_INGESTION,

  cmsMediaURL: "https://stage.content.ecoreps.huck-it.de/assets",
  logoutUrl: baseUrl,
  loginCallback: "https://huck-it.de/ecoreps/spa/dist",
  landingPageUrl: baseUrl,
  auth0: {
    ...env.auth,
    redirectUri: baseUrl
  },
  paypal: {
    clientId:
      "ATjzWrF0Fnw2xZBX287w8hL5-39YeSKc8KootJD1XjMuaBGakaNzSVtkvFokqTAUqwcTlW6sLHx-B7Rw"
  },
  paypalKKL: {
    clientId:
      "ARRO2U1gsiEPrxo3tsuqIIfPBgjUZEc8JyoG5QsW9X6YZTY5kW5sV7gNPTeqdw1fZVyWP1YOoDsrKoWG"
  },
  features: {
    search: true,
    mediaComments: true,
  }
};
