import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpService } from '@core/http';
import { ICourse } from '@models/course';
import { IProgressChapterEntry } from '@models/profile';
import { EnrollmentService } from '@services/enrollment.service';
import { createProfileUrl } from '@utils/urlFactory';
import { Observable, of } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';

export interface IProgressService {
  progressSummary(course: ICourse): Observable<unknown>;
  resetProgress(
    course: ICourse,
    column: string
  ): Observable<IProgressChapterEntry>;
  checkCompleted(
    course: ICourse,
    chapterId: string
  ): Observable<IProgressChapterEntry>;
}

@Injectable({
  providedIn: 'root'
})
export class ProgressService implements IProgressService {
  constructor(
    private client: HttpService<ICourse>,
    private enrollmentService: EnrollmentService
  ) {}

  public progressSummary = (course: ICourse = null): Observable<unknown> => {
    const segments = ['progress', course ? course.url_slug : null]
      .filter(Boolean)
      .join('/');

    return this.client
      .get<IProgressChapterEntry>(
        createProfileUrl(segments),
        undefined,
        (e) => e.status === HttpStatusCode.PaymentRequired
      )
      .pipe(
        tap((progress) => {
          this.enrollmentService.setEnrolmentExists(progress.enrollment);
        }),
        catchError((err: HttpErrorResponse): Observable<unknown> => {
          if (err.status === HttpStatusCode.PaymentRequired) {
            this.enrollmentService.setEnrolmentExists(false);
          }
          return of(err);
        })
      );
  };

  public resetProgress = (
    course: ICourse,
    column: string
  ): Observable<IProgressChapterEntry> => {
    const segments = ['progress', course.url_slug, column].filter(Boolean);
    return this.client.delete<IProgressChapterEntry>(
      createProfileUrl(...segments)
    );
  };

  public checkCompleted = (
    course: ICourse,
    chapterId: string
  ): Observable<IProgressChapterEntry> => {
    const segments = ['progress', course.url_slug, chapterId].filter(Boolean);
    return this.client.get<IProgressChapterEntry>(
      createProfileUrl(...segments)
    );
  };
}
