import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import BasicRxComponent from '@components/BasicRxComponent';
import { LoaderModule } from '@components/loader/loader.module';
import { VideoPlayerModule } from '@components/video-player/video-player.module';
import { IMediaItem } from '@models/comment';
import {
  MediaCaptureService,
  VimeoUploadStatus
} from '@services/media-capture';
import { BehaviorSubject, from } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-video-content',
  standalone: true,
  imports: [CommonModule, VideoPlayerModule, LoaderModule],
  templateUrl: './video-content.component.html',
  styleUrl: './video-content.component.scss',
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VideoContentComponent extends BasicRxComponent implements OnInit {
  @Input() media: IMediaItem;

  isVideoExpand: BehaviorSubject<boolean> = new BehaviorSubject(false);
  mediaFileData:
    | { width: number; height: number; status: VimeoUploadStatus, link }
    | undefined;

  isLoading = new BehaviorSubject<boolean>(false);

  @HostBinding('class.expand') expandClass = false;
  @HostBinding('style.padding-bottom') get hostPaddingBottom() {
    if (!this.mediaFileData || !this.expandClass) {
      return;
    }

    const { width, height } = this.mediaFileData;
    const HOST_DEFAULT_HEIGHT = 40; // Look at host css
    return `calc(${(height / width) * 100}% + ${HOST_DEFAULT_HEIGHT}px)`;
  }
  constructor(
    private mediaCapturer: MediaCaptureService,
    private cdr: ChangeDetectorRef
  ) {
    super();
  }

  toggleVideoPlayer() {
    this.isVideoExpand.next(!this.isVideoExpand.getValue());
  }

  ngOnInit() {
    this.bag.add(
      this.isVideoExpand
        .pipe(
          tap((v: boolean) => {
            this.expandClass = v;
          })
        )
        .subscribe()
    );
    this.bag.add(
      this.isVideoExpand
        .pipe(
          filter(Boolean),
          // No need to download media file data again if user toggle control
          filter(() => !this.mediaFileData),
          switchMap(() => {
            return from(this.downloadMediaFileData());
          })
        )
        .subscribe(({ width, height, status, link }) => {
          this.mediaFileData = { width, height, status, link };
          this.cdr.markForCheck();
        })
    );
    this.isLoading.subscribe((t) => console.log(t));
  }

  private async getMediaFileData(): Promise<
    | { height: number; width: number; status: VimeoUploadStatus; link: string }
    | undefined
  > {
    const media = await this.mediaCapturer.getMedia(this.media.id);
    if (media) {
      const { width, height, status, link } = media;
      return { width, height, status, link };
    }
  }

  private startLoading() {
    this.isLoading.next(true);
  }

  private stopLoading() {
    this.isLoading.next(false);
  }

  private async downloadMediaFileData() {
    try {
      this.startLoading();
      const data = await this.getMediaFileData();
      return data;
    } catch (e) {
      console.error(e);
    } finally {
      this.stopLoading();
    }
  }
}
