import { Injectable } from '@angular/core';
import { BehaviorSubject, fromEvent } from 'rxjs';
import {
  distinctUntilChanged,
  map,
  throttleTime
} from 'rxjs/operators';

enum Platforms {
  MOBILE = 'mobile',
  TABLET = 'tablet',
  DESKTOP = 'desktop'
}

@Injectable({
  providedIn: 'root'
})
export class PlatformService {
  private static readonly MOBILE_MAX_WIDTH = 767;
  private static readonly TABLET_MAX_WIDTH = 1024;

  public platform$: BehaviorSubject<Platforms> =
    new BehaviorSubject<Platforms | null>(null);

  public onInit() {
    this.platform$.next(this.getPlatform());
    fromEvent(window, 'resize')
      .pipe(
        map(() => this.getPlatform()),
        throttleTime(250),
        distinctUntilChanged()
      )
      .subscribe(this.platform$);
  }

  private getPlatform(): Platforms {
    const width = window.innerWidth;

    if (width <= PlatformService.MOBILE_MAX_WIDTH) {
      return Platforms.MOBILE;
    } else if (width <= PlatformService.TABLET_MAX_WIDTH) {
      return Platforms.TABLET;
    } else {
      return Platforms.DESKTOP;
    }
  }

  public isMobile(): boolean {
    return this.platform$.getValue() === Platforms.MOBILE;
  }

  public isTablet(): boolean {
    return this.platform$.getValue() === Platforms.TABLET;
  }

  public isDesktop(): boolean {
    return this.platform$.getValue() === Platforms.DESKTOP;
  }
}
