<div id="page-container" [class]="(cssClass | async)">
  <topbar *ngIf="(topBar | async).show"></topbar>
  <div class="app-content" [class.with-sidebar]="(sideBar | async).show">
    <sidebar *ngIf="(sideBar | async).show"></sidebar>
    <router-outlet></router-outlet>
  </div>
  <footer-component *ngIf="(topBar | async).show"></footer-component>
  <div id="modalContainer" class="modal-container"></div>
</div>

<app-dialog
  [id]="modalService.MODAL_IDS.SESSION_EXPIRED"
  [closable]="false"
  [title]="'Deine Sitzung ist abgelaufen'"
  [submitText]="'Erneut einloggen'"
  [cancelText]="'Zurück zur Landingpage'"
  (onSubmit)="reLogin()"
  (onCancel)="logout()"
>
  <p>
    Dies kann passieren, wenn du ecoreps für einige Zeit nicht benutzt hast,
    oder in einem anderen Browser-Tab ausgeloggt wurdest.
  </p>
  <p>
    Du kannst dich erneut einloggen um eine neue Sitzung zu beginnen.
  </p>
</app-dialog>

<div [class]="dontwaste"></div>
